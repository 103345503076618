import { Table, Tag, Modal } from "antd";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

export default function Avances() {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/avances`)
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // General:
  // FechaCreacion, UvieID, ClienteID, DescripcionBreve, Status, Monto, Notas, FechaRecepcion, FechaContrato, FolioAmuvie,

  // RazonSocial:
  // PV, DatoPV, TelefonoFijoPV, TelefonoMovilPV, CorreoElectronicoPV,
  // ClasificacionSCIANID, NombreComercial,
  // CodigoPostal, Estado, Municipio, Ciudad, Asentamiento, Colonia,
  // NombreCalle, NumeroExterior, NumeroInterior, EntreCalle1, EntreCalle2, LinkMaps,

  // Personas:
  // PF, DatoPF, TelefonoFijoPF, TelefonoMovilPF, CorreoElectronicoPF,
  // PA, DatoPA, TelefonoFijoPA, TelefonoMovilPA, CorreoElectronicoPA, CargoPA, FolioPA, DomicilioPA,
  // PS, DatoPS, TelefonoFijoPS, TelefonoMovilPS, CorreoElectronicoPS,

  // Instalacion:
  // TensionElectricaSuministro, CargaInstalada, AlcanceVerificacion, Capacidad, SuperficieDPEA,
  // TipoTR, CapacidadTR, MarcaTR, NumeroSerieTR, ImpedanciaTR, FechaFabricacionTR, EficienciaTR,
  // TipoVerificacionID, TipoInstalacionID, TipoConcentracionID, TipoInstalacionOtro, TipoVialidad, Nom007, Nom013,

  // Visita:
  // FechaVisita, HoraInicio, HoraTermino,
  // ObjetoVisita, CircunstanciaVisita, NoConformidadesEncargado, ObservacionesEncargado, Acciones,
  // ResResistenciaAislamiento, EqResistenciaAislamiento, ResContinuidadElectrica, EqContinuidadElectrica,
  // ResResistenciaTierraFisica, EqResistenciaTierraFisica, ResPolaridadContactos, EqPolaridadContactos,
  // PT1, DatoPT1, DomicilioPT1, PT2, DatoPT2, DomicilioPT2, ListaTipo, ListaInspeccion

  const generalFields = [
    { title: "Fecha de Creación", dataIndex: "FechaCreacion", key: "FechaCreacion", required: false },
    { title: "Unidad", dataIndex: "UvieID", key: "UvieID", required: false },
    { title: "Cliente", dataIndex: "ClienteID", key: "ClienteID", required: false },
    { title: "Descripción Breve", dataIndex: "DescripcionBreve", key: "DescripcionBreve", required: false },
    { title: "Status", dataIndex: "Status", key: "Status", required: false },
    { title: "Monto", dataIndex: "Monto", key: "Monto", required: true },
    { title: "Notas", dataIndex: "Notas", key: "Notas", required: false },
    { title: "Fecha de Recepción", dataIndex: "FechaRecepcion", key: "FechaRecepcion", required: true },
    { title: "Fecha de Contrato", dataIndex: "FechaContrato", key: "FechaContrato", required: true },
    { title: "Folio AMUVIE", dataIndex: "FolioAmuvie", key: "FolioAmuvie", required: true },
  ];

  const razonSocialFields = [
    { title: "Razón social", dataIndex: "PV", key: "PV", required: true },
    { title: "Identificación de la Razón Social", dataIndex: "DatoPV", key: "DatoPV", required: true },
    { title: "Teléfono Fijo", dataIndex: "TelefonoFijoPV", key: "TelefonoFijoPV", required: false },
    { title: "Teléfono Móvil", dataIndex: "TelefonoMovilPV", key: "TelefonoMovilPV", required: true },
    { title: "Correo Electrónico", dataIndex: "CorreoElectronicoPV", key: "CorreoElectronicoPV", required: true },
    { title: "Clasificación SCIAN", dataIndex: "ClasificacionSCIANID", key: "ClasificacionSCIANID", required: true },
    { title: "Nombre Comercial", dataIndex: "NombreComercial", key: "NombreComercial", required: false },
    { title: "Código Postal", dataIndex: "CodigoPostal", key: "CodigoPostal", required: true },
    { title: "Estado", dataIndex: "Estado", key: "Estado", required: true },
    { title: "Municipio", dataIndex: "Municipio", key: "Municipio", required: true },
    { title: "Ciudad", dataIndex: "Ciudad", key: "Ciudad", required: true },
    { title: "Asentamiento", dataIndex: "Asentamiento", key: "Asentamiento", required: true },
    { title: "Colonia", dataIndex: "Colonia", key: "Colonia", required: true },
    { title: "Nombre de la Calle", dataIndex: "NombreCalle", key: "NombreCalle", required: true },
    { title: "Número Exterior", dataIndex: "NumeroExterior", key: "NumeroExterior", required: true },
    { title: "Número Interior", dataIndex: "NumeroInterior", key: "NumeroInterior", required: false },
    { title: "Entre Calle 1", dataIndex: "EntreCalle1", key: "EntreCalle1", required: true },
    { title: "Entre Calle 2", dataIndex: "EntreCalle2", key: "EntreCalle2", required: false },
    { title: "Link de Google Maps", dataIndex: "LinkMaps", key: "LinkMaps", required: false },
  ];

  const personasFields = [
    { title: "Persona Física", dataIndex: "PF", key: "PF", required: true },
    { title: "Identificación del que firma", dataIndex: "DatoPF", key: "DatoPF", required: true },
    { title: "Teléfono Fijo del que firma", dataIndex: "TelefonoFijoPF", key: "TelefonoFijoPF", required: false },
    { title: "Teléfono Móvil del que firma", dataIndex: "TelefonoMovilPF", key: "TelefonoMovilPF", required: true },
    { title: "Correo Electrónico del que firma", dataIndex: "CorreoElectronicoPF", key: "CorreoElectronicoPF", required: true },
    { title: "Persona que atiende", dataIndex: "PA", key: "PA", required: true },
    { title: "Identificación del que atiende", dataIndex: "DatoPA", key: "DatoPA", required: true },
    { title: "Teléfono Fijo del que atiende", dataIndex: "TelefonoFijoPA", key: "TelefonoFijoPA", required: false },
    { title: "Teléfono Móvil del que atiende", dataIndex: "TelefonoMovilPA", key: "TelefonoMovilPA", required: true },
    { title: "Correo Electrónico del que atiende", dataIndex: "CorreoElectronicoPA", key: "CorreoElectronicoPA", required: true },
    { title: "Cargo del que atiende", dataIndex: "CargoPA", key: "CargoPA", required: true },
    { title: "Folio del que atiende", dataIndex: "FolioPA", key: "FolioPA", required: true },
    { title: "Domicilio del que atiende", dataIndex: "DomicilioPA", key: "DomicilioPA", required: true },
    { title: "Persona que solicita", dataIndex: "PS", key: "PS", required: true },
    { title: "Identificación del que solicita", dataIndex: "DatoPS", key: "DatoPS", required: true },
    { title: "Teléfono Fijo del que solicita", dataIndex: "TelefonoFijoPS", key: "TelefonoFijoPS", required: false },
    { title: "Teléfono Móvil del que solicita", dataIndex: "TelefonoMovilPS", key: "TelefonoMovilPS", required: true },
    { title: "Correo Electrónico del que solicita", dataIndex: "CorreoElectronicoPS", key: "CorreoElectronicoPS", required: true },
  ];

  const instalacionFields = [
    {
      title: "Tensión Eléctrica de Suministro",
      dataIndex: "TensionElectricaSuministro",
      key: "TensionElectricaSuministro",
      required: true,
    },
    { title: "Carga Instalada", dataIndex: "CargaInstalada", key: "CargaInstalada", required: true },
    { title: "Alcance de Verificación", dataIndex: "AlcanceVerificacion", key: "AlcanceVerificacion", required: true },
    { title: "Capacidad", dataIndex: "Capacidad", key: "Capacidad", required: true },
    { title: "Superficie DPEA", dataIndex: "SuperficieDPEA", key: "SuperficieDPEA", required: false },
    { title: "Tipo de Transformador", dataIndex: "TipoTR", key: "TipoTR", required: false },
    { title: "Capacidad del Transformador", dataIndex: "CapacidadTR", key: "CapacidadTR", required: false },
    { title: "Marca del Transformador", dataIndex: "MarcaTR", key: "MarcaTR", required: false },
    { title: "Número de Serie del Transformador", dataIndex: "NumeroSerieTR", key: "NumeroSerieTR", required: false },
    { title: "Impedancia del Transformador", dataIndex: "ImpedanciaTR", key: "ImpedanciaTR", required: false },
    { title: "Fecha de Fabricación del Transformador", dataIndex: "FechaFabricacionTR", key: "FechaFabricacionTR", required: false },
    { title: "Eficiencia del Transformador", dataIndex: "EficienciaTR", key: "EficienciaTR", required: false },
    { title: "Tipo de Verificación", dataIndex: "TipoVerificacionID", key: "TipoVerificacionID", required: true },
    { title: "Tipo de Instalación", dataIndex: "TipoInstalacionID", key: "TipoInstalacionID", required: true },
    { title: "Tipo de Concentración", dataIndex: "TipoConcentracionID", key: "TipoConcentracionID", required: true },
    { title: "Tipo de Instalación Otro", dataIndex: "TipoInstalacionOtro", key: "TipoInstalacionOtro", required: false },
    { title: "Tipo de Vialidad", dataIndex: "TipoVialidad", key: "TipoVialidad", required: false },
    { title: "Norma NOM-007", dataIndex: "Nom007", key: "Nom007", required: true },
    { title: "Norma NOM-013", dataIndex: "Nom013", key: "Nom013", required: true },
  ];

  const visitaFields = [
    { title: "Fecha de Visita", dataIndex: "FechaVisita", key: "FechaVisita", required: true },
    { title: "Hora de Inicio", dataIndex: "HoraInicio", key: "HoraInicio", required: true },
    { title: "Hora de Término", dataIndex: "HoraTermino", key: "HoraTermino", required: true },
    { title: "Objeto de la Visita", dataIndex: "ObjetoVisita", key: "ObjetoVisita", required: true },
    { title: "Circunstancia de la Visita", dataIndex: "CircunstanciaVisita", key: "CircunstanciaVisita", required: true },
    { title: "No Conformidades del Encargado", dataIndex: "NoConformidadesEncargado", key: "NoConformidadesEncargado", required: true },
    { title: "Observaciones del Encargado", dataIndex: "ObservacionesEncargado", key: "ObservacionesEncargado", required: true },
    { title: "Acciones", dataIndex: "Acciones", key: "Acciones", required: true },
    {
      title: "Resultado de la Resistencia de Aislamiento",
      dataIndex: "ResResistenciaAislamiento",
      key: "ResResistenciaAislamiento",
      required: true,
    },
    {
      title: "Equipo de la Resistencia de Aislamiento",
      dataIndex: "EqResistenciaAislamiento",
      key: "EqResistenciaAislamiento",
      required: true,
    },
    {
      title: "Resultado de la Continuidad Eléctrica",
      dataIndex: "ResContinuidadElectrica",
      key: "ResContinuidadElectrica",
      required: true,
    },
    { title: "Equipo de la Continuidad Eléctrica", dataIndex: "EqContinuidadElectrica", key: "EqContinuidadElectrica", required: true },
    {
      title: "Resultado de la Resistencia de Tierra Física",
      dataIndex: "ResResistenciaTierraFisica",
      key: "ResResistenciaTierraFisica",
      required: true,
    },
    {
      title: "Equipo de la Resistencia de Tierra Física",
      dataIndex: "EqResistenciaTierraFisica",
      key: "EqResistenciaTierraFisica",
      required: true,
    },
    { title: "Resultado de la Polaridad de Contactos", dataIndex: "ResPolaridadContactos", key: "ResPolaridadContactos", required: true },
    { title: "Equipo de la Polaridad de Contactos", dataIndex: "EqPolaridadContactos", key: "EqPolaridadContactos", required: true },
    { title: "PT1", dataIndex: "PT1", key: "PT1", required: true },
    { title: "Dato del PT1", dataIndex: "DatoPT1", key: "DatoPT1", required: true },
    { title: "Domicilio del PT1", dataIndex: "DomicilioPT1", key: "DomicilioPT1", required: true },
    { title: "PT2", dataIndex: "PT2", key: "PT2", required: false },
    { title: "Dato del PT2", dataIndex: "DatoPT2", key: "DatoPT2", required: false },
    { title: "Domicilio del PT2", dataIndex: "DomicilioPT2", key: "DomicilioPT2", required: false },
    { title: "Lista Tipo", dataIndex: "ListaTipo", key: "ListaTipo", required: false },
    { title: "Lista Inspección", dataIndex: "ListaInspeccion", key: "ListaInspeccion", required: false },
  ];

  //   const countNonNulls = (fields, data) => {
  //     const requiredFields = fields.filter((field) => field.required);
  //     const nonNullRequiredFields = requiredFields.filter((field) => data[field.dataIndex]);
  //     return `${nonNullRequiredFields.length}/${requiredFields.length}`;
  //   };

  //   const renderCheck = (count) => (
  //     <>
  //       <CheckCircleTwoTone twoToneColor="#66CD00" />
  //       <Tag color="green" style={{ marginLeft: "5px" }}>
  //         <Link
  //           to="#"
  //           onClick={() => {
  //             // modal
  //           }}
  //         >
  //           {count}
  //         </Link>
  //       </Tag>
  //     </>
  //   );

  //   const renderClose = (count) => (
  //     <>
  //       <CloseCircleTwoTone twoToneColor="red" />
  //       <Tag color="red" style={{ marginLeft: "5px" }}>
  //         <Link to="#">{count}</Link>
  //       </Tag>
  //     </>
  //   );

  //   const renderCount = (fields, record) => {
  //     const count = countNonNulls(fields, record);
  //     const [nonNulls, total] = count.split("/");
  //     return nonNulls === total ? renderCheck(count) : renderClose(count);
  //   };

  const countNonNulls = (fields, data) => {
    const requiredFields = fields.filter((field) => field.required);
    const nonNullRequiredFields = requiredFields.filter((field) => data[field.dataIndex]);
    return `${nonNullRequiredFields.length}/${requiredFields.length}`;
  };

  const renderCheck = (count, onClick) => (
    <>
      <CheckCircleTwoTone twoToneColor="#66CD00" />
      <Tag color="green" style={{ marginLeft: "5px" }}>
        <Link to="#" onClick={onClick}>
          {count}
        </Link>
      </Tag>
    </>
  );

  const renderClose = (count, onClick) => (
    <>
      <CloseCircleTwoTone twoToneColor="red" />
      <Tag color="red" style={{ marginLeft: "5px" }}>
        <Link to="#" onClick={onClick}>
          {count}
        </Link>
      </Tag>
    </>
  );

  //   const renderCount = (fields, record) => {
  //     const count = countNonNulls(fields, record);
  //     const [nonNulls, total] = count.split("/");
  //     const isComplete = nonNulls === total;
  //     const onClick = () => {
  //       Modal.info({
  //         title: "Fields",
  //         content: (
  //           <Table
  //             dataSource={fields}
  //             columns={[
  //               { title: "Title", dataIndex: "title" },
  //               { title: "Value", dataIndex: "dataIndex", render: (dataIndex) => record[dataIndex] },
  //             ]}
  //             pagination={false}
  //           />
  //         ),
  //       });
  //     };
  //     return isComplete ? renderCheck(count, onClick) : renderClose(count, onClick);
  //   };

  const renderCount = (fields, record) => {
    const count = countNonNulls(fields, record);
    const [nonNulls, total] = count.split("/");
    const requiredFields = fields.filter((field) => field.required);
    const nonRequiredFields = fields.filter((field) => !field.required);
    const content = (
      <div>
        <p>
          <strong>Campos obligatorios:</strong>
        </p>
        <ul>
          {requiredFields.map((field) => (
            <li key={field.dataIndex}>
              <strong>{field.title}:</strong>{" "}
              {record[field.dataIndex] ? record[field.dataIndex] : <span style={{ color: "red" }}>Vacío</span>}
            </li>
          ))}
        </ul>
        <p>
          <strong>Campos opcionales:</strong>
        </p>
        <ul>
          {nonRequiredFields.map((field) => (
            <li key={field.dataIndex}>
              <strong>{field.title}:</strong>{" "}
              {record[field.dataIndex] ? record[field.dataIndex] : <span style={{ color: "red" }}>Vacío</span>}
            </li>
          ))}
        </ul>
      </div>
    );

    return nonNulls === total ? (
      <span onClick={() => Modal.info({ title: "", content })}>{renderCheck(count)}</span>
    ) : (
      <span onClick={() => Modal.info({ title: "", content })}>{renderClose(count)}</span>
    );
  };

  const columns = [
    {
      title: "Control interno",
      dataIndex: "ControlInterno",
      key: "ControlInterno",
    },
    {
      title: "Generales",
      render: (text, record) => renderCount(generalFields, record),
    },
    {
      title: "Razón social",
      render: (text, record) => renderCount(razonSocialFields, record),
    },
    {
      title: "Personas",
      render: (text, record) => renderCount(personasFields, record),
    },
    {
      title: "Instalación",
      render: (text, record) => renderCount(instalacionFields, record),
    },
    {
      title: "Visita",
      render: (text, record) => renderCount(visitaFields, record),
    },
  ];

  return (
    <div>
      <Table columns={columns} dataSource={data} loading={loading} />
    </div>
  );
}
