import { Card, Button, message } from "antd";
import { CheckCircleFilled, DownloadOutlined, FilePdfFilled } from "@ant-design/icons";
import axios from "axios";

export default function TemplatePreview(props) {
  const { templateFields, templateName, id, loading, title } = props;
  // const Filled = Object.fromEntries(
  //   Object.entries(templateFields).filter(([key, value]) => value !== null && value !== "" && value !== undefined)
  // );
  // const nullKeys = Object.keys(templateFields).filter((key) => templateFields[key] === null);
  // const CountOfFilled = Object.keys(Filled).length;
  // const CountOfAll = Object.keys(templateFields).length;
  const Filled = Object.fromEntries(Object.entries(templateFields).filter(([key, value]) => value != null && value !== ""));
  const nullKeys = Object.keys(templateFields).filter(
    (key) => templateFields[key] === null || templateFields[key] === "" || templateFields[key] === undefined
  );
  const CountOfFilled = Object.keys(Filled).length;
  const CountOfAll = Object.keys(templateFields).length;

  const pdfStyle = {
    fontSize: "20px",
    color: "#AA0A00",
  };

  return (
    <div>
      <Card
        actions={[
          <>
            <Button
              type="default"
              key="downloadpdf"
              icon={<FilePdfFilled style={pdfStyle} />}
              onClick={() => {}}
              disabled={CountOfFilled !== CountOfAll}
              loading={loading}
            />
            <Button
              key="download"
              icon={<DownloadOutlined />}
              onClick={() => {
                axios({
                  method: "get",
                  url: `${process.env.REACT_APP_API_URL}/formatos/${templateName}/${id}`,
                  responseType: "blob",
                })
                  .then((response) => {
                    console.log(response.headers["content-type"]);
                    if (response.headers["content-type"] === "application/zip") {
                      const fileName = `${templateName}_${new Date().getTime()}.zip`;
                      const blob = new Blob([response.data], { type: "application/zip" });
                      const link = document.createElement("a");
                      link.href = window.URL.createObjectURL(blob);
                      link.download = fileName;
                      link.click();
                    } else if (
                      response.headers["content-type"] === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    ) {
                      const fileName = `${templateName}_${new Date().getTime()}.docx`;
                      const blob = new Blob([response.data], {
                        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                      });
                      const link = document.createElement("a");
                      link.href = window.URL.createObjectURL(blob);
                      link.download = fileName;
                      link.click();
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    message.error("Error al generar el documento");
                  });
              }}
              disabled={CountOfFilled !== CountOfAll}
              loading={loading}
            >
              Bajar
            </Button>
          </>,
        ]}
        title={title}
        size="small"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
            padding: "1rem",
          }}
        >
          {CountOfFilled === CountOfAll ? (
            <CheckCircleFilled style={{ color: "#52c41a" }} />
          ) : (
            <Button
              type="link"
              onClick={() => {
                //console log the filled fields and the null fields arrays
                console.table(templateFields);
                console.table(Filled);
                console.table(nullKeys);
                message.error({
                  content: (
                    <>
                      Campos faltantes en {title}:
                      <br />
                      {nullKeys.map((key, index) => (
                        <span key={key}>
                          {index + 1}. {key}
                          <br />
                        </span>
                      ))}
                    </>
                  ),
                  duration: 5,
                });
              }}
            >
              {`${CountOfFilled}/${CountOfAll}`}
            </Button>
          )}
        </div>
      </Card>
    </div>
  );
}
